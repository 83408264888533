import React, { Fragment, useContext } from 'react'
import { RemoteConfigContext } from '../contexts'
import { Helmet } from 'react-helmet'

export default ({
  title,
  description,
  imageURL,
  className,
  hidden = false
}) => {
  const { remoteConfig } = useContext(RemoteConfigContext)

  let headTitle
  if (!title) {
    headTitle = remoteConfig.appName
  } else {
    headTitle = `${title} | ${remoteConfig.appName}`
  }

  if (!description) {
    description = remoteConfig.tagline
  }
  if (!imageURL) {
    imageURL = imageURL = remoteConfig.logoImageURL
  }
  return (
    <Fragment>
      {!hidden && <h1 className={className}>{title}</h1>}
      <Helmet>
        <title>{headTitle}</title>
        <meta name="og:title" content={headTitle} />
        <meta name="description" content={description} />
        <meta
          property="og:description"
          name="og:description"
          content={description}
        />
        <meta property="og:image" name="og:image" content={imageURL} />
        <meta property="image" name="image" content={imageURL} />
        <meta
          property="twitter:image"
          name="twitter:image"
          content={imageURL}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
    </Fragment>
  )
}
